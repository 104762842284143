<template>
  <b-overlay
    style="min-height: 200px"
    :show="showSpinner"
  >
    <div v-if="!showSpinner">
      <b-row class="match-height">
        <b-col
          cols="12"
          md="7"
        >
          <b-card title="Correspondentieadres">
            <b-row>
              <b-col
                cols="12"
                md="5"
              >
                <validation-provider
                  #default="validationContext"
                  name="postalCode"
                  rules=""
                >
                  <b-form-group
                    label="Postcode"
                    label-for="postalCode"
                  >
                    <b-form-input
                      id="postalCode"
                      v-model="relation.postalCode"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="houseNumber"
                  rules=""
                >
                  <b-form-group
                    label="Huisnummer"
                    label-for="houseNumber"
                  >
                    <b-form-input
                      id="houseNumber"
                      v-model="relation.houseNumber"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="houseNumberAddition"
                  rules=""
                >
                  <b-form-group
                    label="Huisnummertoevoeging"
                    label-for="houseNumberAddition"
                  >
                    <b-form-input
                      id="houseNumberAddition"
                      v-model="relation.houseNumberAddition"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="street"
                  rules=""
                >
                  <b-form-group
                    label="Straat"
                    label-for="street"
                  >
                    <b-form-input
                      id="street"
                      v-model="relation.street"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="city"
                  rules=""
                >
                  <b-form-group
                    label="Plaats"
                    label-for="city"
                  >
                    <b-form-input
                      id="city"
                      v-model="relation.city"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-card title="Contactgegevens">
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="mb-1"
              >
                <validation-provider
                  #default="validationContext"
                  name="gender"
                  rules=""
                >
                  <label>Geslacht</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="relation.gender"
                      name="gender"
                      value="m"
                      class="mt-0"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    >
                      Man
                    </b-form-radio>
                    <b-form-radio
                      v-model="relation.gender"
                      name="gender"
                      value="f"
                      class="mt-0"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    >
                      Vrouw
                    </b-form-radio>
                  </div>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="firstName"
                  rules=""
                >
                  <b-form-group
                    label="Voornaam"
                    label-for="firstName"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="relation.firstName"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Tussenvoegsel"
                  label-for="middleName"
                >
                  <b-form-input
                    id="middleName"
                    v-model="relation.middleName"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="5"
              >
                <validation-provider
                  #default="validationContext"
                  name="lastName"
                  rules=""
                >
                  <b-form-group
                    label="Achternaam"
                    label-for="lastName"
                  >
                    <b-form-input
                      id="lastname"
                      v-model="relation.lastName"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="birthDate"
                  rules=""
                >
                  <b-form-group
                    label="Geboortedatum"
                    label-for="birthDate"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      id="birthDate"
                      v-model="relation.birthDate"
                      :config="birthDateConfig"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                      :disable-mobile="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="phoneNumber"
                  rules=""
                >
                  <b-form-group
                    label="Telefoonnummer"
                    label-for="phoneNumber"
                  >
                    <b-form-input
                      id="phoneNumber"
                      v-model="relation.phoneNumber"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="mobileNumber"
                  rules=""
                >
                  <b-form-group
                    label="Mobiel nummer"
                    label-for="mobileNumber"
                  >
                    <b-form-input
                      id="mobileNumber"
                      v-model="relation.mobileNumber"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="email"
                  rules=""
                >
                  <b-form-group
                    label="E-mailadres"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="relation.email"
                      type="email"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-card
            v-if="relation.type === 2"
            title="Bedrijfsgegevens"
          >
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Bedrijfsnaam"
                  rules=""
                >
                  <b-form-group
                    label="Bedrijfsnaam"
                    label-for="companyName"
                  >
                    <b-form-input
                      id="companyName"
                      v-model="relation.companyName"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="companyCocNumber"
                  rules=""
                >
                  <b-form-group
                    label="KVK-nummer"
                    label-for="companyCocNumber"
                  >
                    <b-form-input
                      id="companyCocNumber"
                      v-model="relation.companyCocNumber"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="contactPerson"
                  rules=""
                >
                  <b-form-group
                    label="Contactpersoon"
                    label-for="contactPerson"
                  >
                    <b-form-input
                      id="contactPerson"
                      v-model="relation.contactPerson"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-card title="Betaalgegevens">
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="mb-1"
              >
                <validation-provider
                  #default="validationContext"
                  name="bankAccountNumber"
                  rules=""
                >
                  <b-form-group
                    label="IBAN"
                    label-for="bankAccountNumber"
                  >
                    <b-form-input
                      v-model="relation.bankAccountNumber"
                      name="bankAccountNumber"
                      :state="getValidationState(validationContext)"
                      trim
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="debtorName"
                  rules=""
                >
                  <b-form-group
                    label="Tenaamstelling"
                    label-for="ibanAscription"
                  >
                    <b-form-input
                      id="debtorName"
                      v-model="relation.debtorName"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="paymentMethod"
                  rules="required"
                >
                  <b-form-group
                    label="Betaalwijze"
                    label-for="paymentMethod"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="relation.paymentMethod"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentMethodOptions"
                      :clearable="false"
                      input-id="paymentMethod"
                      :reduce="val => val.value"
                      disabled="disabled"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <b-overlay
            :show="showDraftCalculationSpinner"
          >
            <b-card
              :title="orderData.product.isGV ? 'Gecontracteerde tarieven' : 'Kostenspecificatie'"
            >
              <b-row>
                <b-col
                  v-if="showDraftCalculationSpinner"
                  style="min-height: 200px"
                >
                  &nbsp;
                </b-col>
              </b-row>
              <hr>
              <b-row
                v-if="!showDraftCalculationSpinner"
              >
                <b-col
                  cols="12"
                >
                  <app-collapse
                    v-if="!orderData.product.isGV"
                    accordion
                    type="margin"
                  >
                    <div
                      v-for="item in draftCalculation.calculation"
                      :key="item.ean"
                    >
                      <app-collapse-item
                        :title="getDraftCalculationType(item.summary,item.ean).title"
                        :subject="item.ean"
                        :icon="getDraftCalculationType(item.summary,item.ean).icon"
                      >
                        <hr>
                        <b-row>
                          <b-col
                            cols="12"
                          >
                            <strong>{{ getProductNameForEAN() }}</strong>
                          </b-col>
                        </b-row>
                        <hr>
                        <template>
                          <b-row>
                            <b-col
                              cols="12"
                              class="mt-1"
                            >
                              <strong>Leveringskosten</strong>
                            </b-col>
                            <template
                              v-if="getDraftCalculationComponentIndex(item.calculation, 'Enkel', false) > 0"
                            >
                              <b-col
                                cols="8"
                              >
                                leveringskosten Enkeltarief
                              </b-col>
                              <b-col
                                cols="4"
                                class="text-right"
                              >
                                <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Enkel', false)].totalAmount).toFixed(2) }}</span>
                                <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Enkel', false)].amount).toFixed(2) }}</span>
                              </b-col>
                            </template>
                            <template
                              v-if="getDraftCalculationComponentIndex(item.calculation, 'Hoog', false) > 0"
                            >
                              <b-col
                                cols="8"
                              >
                                Leveringskosten Normaal
                              </b-col>
                              <b-col
                                cols="4"
                                class="text-right"
                              >
                                <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Hoog', false)].totalAmount).toFixed(2) }}</span>
                                <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Hoog', false)].amount).toFixed(2) }}</span>
                              </b-col>
                            </template>
                            <template
                              v-if="getDraftCalculationComponentIndex(item.calculation, 'Laag', false) > 0"
                            >
                              <b-col
                                cols="8"
                              >
                                Leveringskosten Dal
                              </b-col>
                              <b-col
                                cols="4"
                                class="text-right"
                              >
                                <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Laag', false)].totalAmount).toFixed(2) }}</span>
                                <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Laag', false)].amount).toFixed(2) }}</span>
                              </b-col>
                            </template>
                            <template
                              v-if="getDraftCalculationComponentIndex(item.calculation, 'Vastrecht', false) > 0"
                            >
                              <b-col
                                cols="8"
                              >
                                Vaste leveringskosten
                              </b-col>
                              <b-col
                                cols="4"
                                class="text-right"
                              >
                                <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Vastrecht', false)].totalAmount).toFixed(2) }}</span>
                                <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Vastrecht', false)].amount).toFixed(2) }}</span>
                              </b-col>
                            </template>
                            <template
                              v-if="getDraftCalculationComponentIndex(item.calculation, 'Netwerkkosten', false) > 0"
                            >
                              <b-col
                                cols="12"
                                class="mt-1"
                              >
                                <strong>Netbeheerkosten</strong>
                              </b-col>
                              <b-col
                                cols="8"
                              >
                                Netbeheerkosten
                              </b-col>
                              <b-col
                                cols="4"
                                class="text-right"
                              >
                                <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Netwerkkosten', false)].totalAmount).toFixed(2) }}</span>
                                <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Netwerkkosten', false)].amount).toFixed(2) }}</span>
                              </b-col>
                            </template>
                            <b-col
                              cols="12"
                              class="mt-1"
                            >
                              <strong>Belasting en toeslagen</strong>
                            </b-col>
                            <section
                              v-for="energyTaxItem in getCalculationComponents(item.calculation,'Energiebelasting', false)"
                              :key="energyTaxItem.componentType + String(Math.random())"
                              style="width:100%;display: flex"
                            >
                              <b-col
                                cols="9"
                              >
                                Energiebelasting ({{ energyTaxItem.rangeStart }} - {{ energyTaxItem.rangeEnd }} {{ energyTaxItem.priceUnit }})
                              </b-col>
                              <b-col
                                cols="3"
                                class="text-right"
                              >
                                <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(energyTaxItem.totalAmount).toFixed(2) }}</span>
                                <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(energyTaxItem.amount).toFixed(2) }}</span>
                              </b-col>
                            </section>
                            <section
                              v-for="energyTaxItem in getCalculationComponents(item.calculation,'Opslag duurzame energie', false)"
                              :key="energyTaxItem.componentType + String(Math.random())"
                              style="width:100%;display: flex"
                            >
                              <b-col
                                cols="9"
                              >
                                Opslag duurzame energie ({{ energyTaxItem.rangeStart }} - {{ energyTaxItem.rangeEnd }} {{ energyTaxItem.priceUnit }})
                              </b-col>
                              <b-col
                                cols="3"
                                class="text-right"
                              >
                                <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(energyTaxItem.totalAmount).toFixed(2) }}</span>
                                <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(energyTaxItem.amount).toFixed(2) }}</span>
                              </b-col>
                            </section>
                            <template
                              v-if="getDraftCalculationComponentIndex(item.calculation, 'Heffingskorting', false) > 0"
                            >
                              <b-col
                                cols="9"
                              >
                                Vermindering energiebelasting
                              </b-col>
                              <b-col
                                cols="3"
                                class="text-right"
                              >
                                <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Heffingskorting', false)].totalAmount).toFixed(2) }}</span>
                                <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Heffingskorting', false)].amount).toFixed(2) }}</span>
                              </b-col>
                            </template>
                            <b-col
                              cols="12"
                            >
                              <hr>
                            </b-col>
                            <b-col
                              cols="8"
                            >
                              <strong>Totaal</strong>
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1">&euro; {{ parseFloat(item.summary.totalAmount).toFixed(2) }}</span>
                              <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0">&euro; {{ parseFloat(item.summary.totalSubAmount).toFixed(2) }}</span>
                            </b-col>
                          </b-row>
                        </template>
                      </app-collapse-item>
                    </div>
                  </app-collapse>
                  <app-collapse
                    accordion
                    type="margin"
                  >
                    <app-collapse-item
                      title="Uw tarieven"
                      subject="(exclusief btw)"
                      icon="fas fa-euro-sign"
                    >
                      <hr>
                      <b-row
                        v-if="orderData.product.type === 'DUAL' || orderData.product.type === 'ELECTRICITY'"
                      >
                        <b-col
                          cols="12"
                          class="mt-1"
                        >
                          <strong>Elektriciteit</strong>
                        </b-col>
                        <b-col
                          cols="8"
                          style="margin-top:5px"
                        >
                          Enkeltarief
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffSingle }} /kWh</span>
                        </b-col>
                        <b-col
                          cols="8"
                        >
                          Normaaltarief
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffHigh }} /kWh</span>
                        </b-col>
                        <b-col
                          cols="8"
                        >
                          Daltarief
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffLow }} /kWh</span>
                        </b-col>
                        <b-col
                          cols="8"
                        >
                          Teruglevering enkeltarief
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffReturnSingle }} /kWh</span>
                        </b-col>
                        <b-col
                          cols="8"
                        >
                          Teruglevering normaaltarief
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffReturnHigh }} /kWh</span>
                        </b-col>
                        <b-col
                          cols="8"
                        >
                          Teruglevering daltarief
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffReturnLow }} /kWh</span>
                        </b-col>
                        <b-col
                          cols="8"
                        >
                          Vaste kosten
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffFixedEnergy }} /jaar</span>
                        </b-col>
                      </b-row>
                      <b-row v-if="orderData.product.type === 'DUAL' || orderData.product.type === 'GAS'">
                        <b-col
                          cols="12"
                          class="mt-1"
                        >
                          <strong>Gas</strong>
                        </b-col>
                        <b-col
                          cols="8"
                          style="margin-top:5px"
                        >
                          Leveringstarief (G1)
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffGas1 }} /m3</span>
                        </b-col>
                        <b-col
                          cols="8"
                        >
                          Leveringstarief (G2)
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffGas1 }} /m3</span>
                        </b-col>
                        <b-col
                          cols="8"
                        >
                          Vaste kosten
                        </b-col>
                        <b-col
                          cols="4"
                          class="text-right"
                        >
                          <span>&euro;{{ orderData.tariff.tariffFixedGas }} /jaar</span>
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                  </app-collapse>
                </b-col>
              </b-row>
              <b-row
                v-if="!orderData.product.isGV && !showDraftCalculationSpinner"
                class="mt-3"
                style="border-radius:5px;background-color:#fb8500;color:#ffffff;padding-top: 10px;padding-bottom: 10px"
              >
                <b-col
                  cols="8"
                  style="padding-top:5px"
                >
                  <strong>Jaarlijkse kosten ({{ orderData.isBusiness === 0 || orderData.product.isSohoProposition === 0 ? 'incl.' : 'excl.' }} btw)</strong>
                </b-col>
                <b-col
                  cols="4"
                  style="padding-top:5px"
                >
                  <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1"><strong>&euro; {{ parseFloat((draftCalculation.calculationSummary.totalAmount * 12)).toFixed(2) }}</strong></span>
                  <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0"><strong>&euro; {{ parseFloat((draftCalculation.calculationSummary.totalSubAmount * 12)).toFixed(2) }}</strong></span>
                </b-col>
                <b-col
                  cols="8"
                  style="padding-top:5px"
                >
                  <strong>Maandelijkse kosten ({{ orderData.isBusiness === 0 || orderData.product.isSohoProposition === 0 ? 'incl.' : 'excl.' }} btw)</strong>
                </b-col>
                <b-col
                  cols="4"
                  style="padding-top:5px"
                >
                  <span v-if="orderData.isBusiness === 0 || orderData.product.isSohoProposition === 1"><strong>&euro; {{ parseFloat((draftCalculation.calculationSummary.totalAmount)).toFixed(2) }}</strong></span>
                  <span v-if="orderData.isBusiness === 1 && orderData.product.isSohoProposition === 0"><strong>&euro; {{ parseFloat((draftCalculation.calculationSummary.totalSubAmount)).toFixed(2) }}</strong></span>
                </b-col>
                <b-col
                  cols="8"
                  style="padding-top:5px"
                >
                  <strong>btw</strong>
                </b-col>
                <b-col
                  cols="4"
                  style="padding-top:5px"
                >
                  <strong>&euro; {{ parseFloat((draftCalculation.calculationSummary.totalVatAmount)).toFixed(2) }}</strong>
                </b-col>
              </b-row>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col
          v-if="electricityEans.length > 0"
          cols="12"
          md="12"
        >
          <b-card
            title="Elektriciteit EAN's"
          >
            <b-table
              ref="refElectricityEANTable"
              class="position-relative"
              :items="electricityEans"
              responsive
              :fields="electricityEanTableColumns"
              primary-key="id"
              show-empty
              empty-text="Geen overeenkomende records gevonden"
            >
              <template #cell(ean)="data">
                <small>{{ data.value }}</small>
              </template>
              <template #cell(status)="data">
                <b-badge
                  :id="`invoice-row-${data.item.id}-status`"
                  :variant="getStatusType(data.value).variant"
                >
                  {{ getStatusType(data.value).name }}
                </b-badge>
              </template>
              <template #cell(bagPurpose)="data">
                <small>{{ data.value && data.item.usageType !== 'LARGECONSUMER' ? data.value : '' }}</small>
              </template>
              <template #cell(meterType)="data">
                <span
                  v-if="data.item.marketSegment === 'ELECTRICITY' && data.value === 'SINGLE'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">Enkel</small>
                </span>
                <span
                  v-if="data.item.marketSegment === 'ELECTRICITY' && data.value !== 'SINGLE'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">Dubbel</small>
                </span>
              </template>
              <template #cell(marketSegment)="data">
                <span
                  v-if="data.value === 'ELECTRICITY' && data.item.meterType === 'SINGLE'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">Enkel: {{ data.item.usageElectricitySingle }}</small>
                </span>
                <span
                  v-if="data.value === 'ELECTRICITY' && data.item.meterType !== 'SINGLE'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">Normaal: {{ data.item.usageElectricityHigh }}</small>
                </span>
                <div
                  v-if="data.value === 'ELECTRICITY' && data.item.meterType !== 'SINGLE'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">Dal: {{ data.item.usageElectricityLow }}</small>
                </div>
              </template>
              <template #cell(returnElectricitySingle)="data">
                <span
                  v-if="data.item.returnElectricitySingle !== null && data.item.returnElectricitySingle !== 0 && data.item.meterType === 'SINGLE'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">Enkel: {{ data.item.returnElectricitySingle }}</small>
                </span>
                <span
                  v-if="data.item.returnElectricityHigh !== null && data.item.returnElectricityHigh !== 0 && data.item.meterType !== 'SINGLE'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">Normaal: {{ data.item.returnElectricityHigh }}</small>
                </span>
                <span
                  v-if="data.item.returnElectricityLow !== null && data.item.returnElectricityLow !== 0 && data.item.meterType !== 'SINGLE'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">Dal: {{ data.item.returnElectricityLow }}</small>
                </span>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col
          v-if="gasEans.length > 0"
          cols="12"
          md="12"
        >
          <b-card
            title="Gas EAN's"
          >
            <b-table
              ref="refGasEANTable"
              class="position-relative"
              :items="gasEans"
              responsive
              :fields="GasTableColumns"
              primary-key="id"
              show-empty
              empty-text="Geen overeenkomende records gevonden"
            >
              <template #cell(ean)="data">
                <small>{{ data.value }}</small>
              </template>
              <template #cell(bagPurpose)="data">
                <small>{{ data.value && data.item.usageType !== 'LARGECONSUMER' ? data.value : '' }}</small>
              </template>
              <template #cell(marketSegment)="data">
                <span
                  v-if="data.value === 'GAS'"
                  class="font-weight-bold d-block"
                >
                  <small style="width:100%">{{ data.item.usageGas }} m3</small>
                </span>
              </template>
              <template #cell(status)="data">
                <b-badge
                  :id="`invoice-row-${data.item.id}-status`"
                  :variant="getStatusType(data.value).variant"
                >
                  {{ getStatusType(data.value).name }}
                </b-badge>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col
          v-if="ChargingPoints.length > 0 && chargingPointEnabled === 'true'"
          cols="12"
          md="12"
        >
          <b-card
            title="Laadpalen"
          >
            <b-table
              ref="refChargingStationTable"
              class="position-relative"
              :items="ChargingPoints"
              responsive
              :fields="chargingStationTableColumns"
              primary-key="id"
              show-empty
              empty-text="Geen overeenkomende records gevonden"
            >
              <template #cell(chargingPointID)="data">
                <small>{{ data.value }}</small>
              </template>
              <template #cell(chargingPointCarType)="data">
                <small>{{ getChargingPointCarType(data.value) }}</small>
              </template>
              <template #cell(chargingPointPeriodType)="data">
                <small>{{ getChargingPointPeriod(data.value) }}</small>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        class="match-height"
      >
        <b-col
          cols="12"
          md="7"
        >
          <b-card
            title="Ordergegevens"
          >
            <validation-observer
              #default="{ handleSubmit }"
            >
              <b-form
                class="mb-2"
                @submit.prevent="handleSubmit(updateOrderData)"
              >
                <b-row class="mb-1">
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="isRenewal"
                      rules="required"
                    >
                      <label>Verlenging</label>
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="isRenewal"
                          name="isRenewal"
                          :value="0"
                          class="mt-0"
                          :disabled="orderData.ecedoCreated === 1"
                          :state="getValidationState(validationContext)"
                        >
                          Nee
                        </b-form-radio>
                        <b-form-radio
                          v-model="isRenewal"
                          name="isRenewal"
                          :value="1"
                          class="mt-0"
                          :disabled="orderData.ecedoCreated === 1"
                          :state="getValidationState(validationContext)"
                        >
                          Ja
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="renewalType"
                      rules="required_if:isRenewal,1"
                    >
                      <label>Verlengingstype</label>
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="renewalType"
                          name="renewalType"
                          value="CONTRACT"
                          class="mt-0"
                          :disabled="isRenewal=== 0 || orderData.ecedoCreated === 1"
                          :state="getValidationState(validationContext)"
                        >
                          Contractverlenging
                        </b-form-radio>
                        <b-form-radio
                          v-model="renewalType"
                          name="renewalType"
                          value="VARIABLECONTRACT"
                          class="mt-0"
                          :disabled="isRenewal=== 0|| orderData.ecedoCreated === 1"
                          :state="getValidationState(validationContext)"
                        >
                          Variabel naar Vast
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="startDateContract"
                      rules="required_if:isRenewal,0|required_if:renewalType,VARIABLECONTRACT,null"
                    >
                      <b-form-group
                        label="Overstapdatum"
                        label-for="startDateContract"
                        :state="getValidationState(validationContext)"
                      >
                        <flat-pickr
                          id="startDateContract"
                          v-model="startDate"
                          :config="startDateContractConfig"
                          :state="getValidationState(validationContext)"
                          :disabled="renewalType==='CONTRACT' || orderData.ecedoCreated === 1"
                          :disable-mobile="renewalType==='CONTRACT' || orderData.ecedoCreated === 1"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row
                  v-if="orderData.ecedoCreated === 0"
                >
                  <b-col cols="12 text-right mb-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                      :disabled="showUpdateOrder"
                    >
                      <b-spinner
                        v-if="showUpdateOrder"
                        small
                        class="mr-1"
                      />
                      <span>Opslaan</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <b-card
            title="Documenten"
          >
            <b-card-body class="text-left">
              <b-row>
                <b-col
                  cols="12"
                >
                  <ul class="pl-0 ml-0">
                    <li>
                      <b-link
                        :href="`${axiosDefaultConfig.backendEndPoint}orders/${orderData.customerToken}/pdf`"
                        target="_blank"
                        variant="info"
                      >
                        <i
                          class="far fa-file-pdf"
                          style="height:16px;padding-right:5px;color:#ea5455"
                        />
                        <span style="color:#000000">{{ orderData.isSigned === 0 ? 'Offerte':'' }}{{ orderData.isSigned === 1 ? 'Orderbevestiging':'' }}</span>
                      </b-link>
                    </li>
                    <li
                      v-for="(item, index) in orderData.product.files"
                      :key="index"
                    >
                      <b-link
                        :href="item.fileURL"
                        target="_blank"
                      >
                        <i
                          class="far fa-file-pdf"
                          style="height:16px;padding-right:5px;color:#ea5455"
                        />
                        <span style="color:#000000">{{ item.description }}</span>
                      </b-link>
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        class="match-height"
      >
        <b-col
          v-if="orderActivity.length > 0"
          cols="12"
          md="7"
        >
          <b-overlay
            :show="showOrderActivitySpinner"
          >
            <b-card
              title="Historie"
            >
              <b-row>
                <b-col
                  v-if="showOrderActivitySpinner"
                  style="min-height: 200px"
                >
                  &nbsp;
                </b-col>
              </b-row>
              <b-row
                v-if="!showOrderActivitySpinner"
              >
                <b-col
                  cols="12"
                >
                  <app-timeline>
                    <app-timeline-item
                      v-for="(data,index) in orderActivity"
                      :key="index"
                      :title="data.title"
                      :subtitle="data.message"
                      :time="formatDateTime(data.timestamp)"
                      variant="secondary"
                    />
                  </app-timeline>
                </b-col>
              </b-row>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <b-card
            title="Acties"
          >
            <b-row>
              <b-col
                v-if="$can('order', 'update') && orderData.status !== 'CANCELLED' && orderData.ecedoCreated === 0"
                cols="12"
                class="mb-2"
              >
                <b-button
                  style="width:100%"
                  variant="danger"
                  @click="cancelOrder"
                >
                  Annuleren
                </b-button>
              </b-col>
              <b-col
                v-if="$can('create', 'email') && orderData.signType === 'EMAIL' && orderData.status !== 'CANCELLED' && orderData.ecedoCreated === 0 && orderData.isSigned === 0"
                cols="12"
                class="mb-2"
              >
                <b-button
                  style="width:100%"
                  variant="secondary"
                  @click="sendEmail"
                >
                  Email opnieuw verzenden
                </b-button>
              </b-col>
              <b-col
                v-if="$can('create', 'sms') && orderData.signType === 'EMAIL' && orderData.status !== 'CANCELLED' && orderData.ecedoCreated === 0 && orderData.isSigned === 0"
                cols="12"
                class="mb-2"
              >
                <b-button
                  style="width:100%"
                  variant="secondary"
                  @click="sendSms"
                >
                  SMS verzenden
                </b-button>
              </b-col>
              <b-col
                v-if="$can('order', 'update') && orderData.needsAttention === 1"
                cols="12"
                class="mb-2"
              >
                <b-button
                  style="width:100%"
                  variant="secondary"
                  @click="resendOrder"
                >
                  Opnieuw verzenden
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        class="match-height"
      >
        <b-col
          cols="12"
        >
          <b-card>
            <b-row>
              <b-col
                cols="6 text-left"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="$router.go(-1)"
                >
                  <span>Terug</span>
                </b-button>
              </b-col>
              <b-col
                cols="6 text-right"
              />
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard, BCardBody,
  BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadio, BLink, BOverlay,
  BRow, BTable,
} from 'bootstrap-vue'
import {
  required, integer, decimal, between,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import orderStoreModule from '@/views/pages/order/orderStoreModule'
import { onUnmounted } from '@vue/composition-api'
import useOrderList from '@/views/pages/order/useOrderList'
import { avatarText } from '@core/utils/filter'
import {
  formatDate,
  formatDateTime,
  getFullName,
  todayMinYears,
} from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import useOrderFlow from '@/views/pages/order-flow/useOrderFlow'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormRadio,
    BFormInvalidFeedback,
    BFormGroup,
    BForm,
    BFormInput,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BBadge,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    BLink,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      chargingPointEnabled: process.env.VUE_APP_CHARGINGPOINT,
      orderData: null,
      birthDateConfig: {
        maxDate: todayMinYears(18),
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      startDateContractConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      yesNoOptions: [
        { label: 'Ja', value: true },
        { label: 'Nee', value: false },
      ],
      gasEans: [],
      electricityEans: [],
      ChargingPoints: [],
      relation: {},
      signType: '',
      startDate: null,
      isRenewal: 0,
      renewalType: null,
      paymentMethod: 0,
      signatureImg: null,
      draftCalculation: {},
      showSpinner: false,
      showDraftCalculationSpinner: false,
      paymentMethodOptions: [
        { label: 'Automatische incasso', value: 'AUTOMATICCOLLECTION' },
      ],
      orderActivity: [],
      showOrderActivitySpinner: false,
      showUpdateOrder: false,
    }
  },
  beforeMount() {
    this.getOrder()
  },
  methods: {
    updateOrderData() {
      store.dispatch('app-order-detail/patchOrder', {
        id: this.orderData.id,
        isRenewal: this.isRenewal,
        renewalType: this.renewalType,
        startDate: this.startDate,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'De order is geupdate.',
              icon: '',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het updaten van uw order details.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendOrder() {
      store.dispatch('app-order-detail/sendERP', { id: this.orderData.id })
        .then(() => {
          this.getOrder()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'De order is opnieuw verzonden naar het ERP systeem.',
              icon: '',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het verzend van de order.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    cancelOrder() {
      store.dispatch('app-order-detail/patchOrder', { id: this.orderData.id, status: 'CANCELLED' })
        .then(() => {
          this.getOrder()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'De order is geannuleerd.',
              icon: '',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het annulleren van uw order.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    sendEmail() {
      store.dispatch('app-order-detail/sendEmail', { id: this.orderData.id })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email is opnieuw verzonden',
              icon: '',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het verzenden van de email',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    sendSms() {
      store.dispatch('app-order-detail/sendSms', { id: this.orderData.id })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sms is verzonden',
              icon: '',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het verzenden van de sms',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getOrderActivity(id) {
      this.showOrderActivitySpinner = true
      store.dispatch('app-order-detail/getOrderActivity', { id })
        .then(response => {
          this.orderActivity = response.data.items
          this.showOrderActivitySpinner = false
        }).catch(() => {
          this.showOrderActivitySpinner = false
        })
    },
    getDraftCalculation(data) {
      this.showDraftCalculationSpinner = true
      store.dispatch('app-order-detail/getDraftCalculation', data).then(response => {
        // eslint-disable-next-line prefer-destructuring
        this.draftCalculation = response.data
        this.showDraftCalculationSpinner = false
      }).catch(() => {
        this.showDraftCalculationSpinner = false
      })
    },
    getCalculationComponents(calculation, componentType) {
      return calculation.filter(comp => comp.componentType === componentType)
    },
    getDraftCalculationComponentIndex(calculation, componentType, isReturn) {
      let data = null

      if (isReturn) {
        data = calculation.findIndex(comp => comp.componentType === componentType && comp.isReturn)
      } else {
        data = calculation.findIndex(comp => comp.componentType === componentType && !comp.isReturn)
      }

      return data
    },
    getDraftCalculationType(summary, ean) {
      if (summary.marketSegment === 'ELECTRICITY' && !this.isChargingPoint(ean)) {
        return { title: 'Stroom', icon: 'fas fa-bolt' }
      }

      if (summary.marketSegment === 'ELECTRICITY' && this.isChargingPoint(ean)) {
        return { title: 'Stroom', icon: 'fas fa-charging-station' }
      }

      if (summary.marketSegment === 'GAS') {
        return { title: 'Gas', icon: 'fas fa-burn' }
      }

      return ''
    },
    isChargingPoint(ean) {
      return this.orderData.connections.some(obj => obj.isChargingPoint === true && obj.ean === ean)
    },
    getProductNameForEAN(ean) {
      if (this.isChargingPoint(ean)) {
        return this.draftCalculation.chargingProduct.name
      }
      return this.draftCalculation.product.name
    },
    getOrder() {
      this.showSpinner = true
      this.signatureImg = null
      store.dispatch('app-order-detail/fetchOrder', { id: router.currentRoute.params.id })
        .then(response => {
          this.orderData = response.data
          this.signType = response.data.signType
          this.relation = response.data.customerRelation
          this.startDate = response.data.connections[0].startDate
          this.isRenewal = response.data.isRenewal
          this.renewalType = response.data.renewalType
          this.signatureImg = response.data.signatureSVG
          this.electricityEans = response.data.connections.filter(connection => connection.marketSegment === 'ELECTRICITY' && !connection.isChargingPoint)
          this.gasEans = response.data.connections.filter(connection => connection.marketSegment === 'GAS')
          this.ChargingPoints = response.data.connections.filter(connection => connection.marketSegment === 'ELECTRICITY' && connection.type === 'CHARGINGPOINT')
          this.showSpinner = false

          if (!response.data.product.isGV) {
            this.getDraftCalculation(response.data)
          }

          this.getOrderActivity(response.data.id)
        })
        .catch(() => {
          this.showSpinner = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het ophalen van de data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    const ORDER_DETAIL_MODULE_NAME = 'app-order-detail'

    // Register module
    if (!store.hasModule(ORDER_DETAIL_MODULE_NAME)) store.registerModule(ORDER_DETAIL_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDER_DETAIL_MODULE_NAME)) store.unregisterModule(ORDER_DETAIL_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    const {
      getChargingPointPeriod,
      getChargingPointCarType,
    } = useOrderFlow()

    const {
      resolveTypeVariant,
      electricityEanTableColumns,
      GasTableColumns,
      chargingStationTableColumns,
      getStatusType,
    } = useOrderList()

    return {
      avatarText,
      refFormObserver,
      getValidationState,
      resetForm,
      required,
      integer,
      decimal,
      between,
      resolveTypeVariant,
      formatDate,
      formatDateTime,
      getFullName,
      electricityEanTableColumns,
      GasTableColumns,
      chargingStationTableColumns,
      getStatusType,
      axiosDefaultConfig,
      getChargingPointPeriod,
      getChargingPointCarType,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.app-timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-left: 1rem;
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient($theme-dark-border-color, $theme-dark-card-bg);
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}

.orderInfo{
    tr{
      th{
        min-width: 155px;
      }
    }
}

.signature.invalid {
  border: solid 2px #ea5455;
}

.invalid{
  .card-title{
    color: #ea5455
  }
}

.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
