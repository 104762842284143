import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
  const toast = useToast()
  const refOfferListTable = ref(null)
  const perPage = ref(10)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const sortByStatus = ref(null)
  const searchQuery = ref(null)
  const userFilter = ref(null)
  const statusFilter = ref(null)
  const dateFormFilter = ref(null)
  const dateTillFilter = ref(null)
  const ecedoCreatedFilter = ref(null)
  const needsAttentionFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refOfferListTable.value ? refOfferListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    }
  })

  const refetchData = () => {
    if (refOfferListTable && refOfferListTable.value) {
      refOfferListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, sortByStatus, searchQuery, userFilter, statusFilter, dateFormFilter, dateTillFilter, ecedoCreatedFilter, needsAttentionFilter], () => {
    refetchData()
  })

  const tableColumns = [
    { label: '', key: 'accepted', sortable: false },
    { label: 'Details', key: 'show_details', sortable: false },
    { label: '#', key: 'id', sortable: true },
    { label: 'Relatie', key: 'customerRelation', sortable: true },
    { label: 'status', key: 'status', sortable: true },
    { label: 'Aangemaakt door', key: 'user.fullname', sortable: true },
    { label: 'Getekend op', key: 'signTimestamp', sortable: true },
    { key: 'actions' },
  ]

  const tableEansColumns = [
    { label: 'EAN', key: 'ean', sortable: true },
    { label: 'Gebruiksdoel', key: 'bagPurpose', sortable: false },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Type', key: 'usageType', sortable: true },
    { label: 'Adres', key: 'postalCode', sortable: true },
    { label: 'Verbruik', key: 'marketSegment', sortable: true },
    { label: 'Startdatum', key: 'startDate', sortable: true },
  ]

  const chargingStationTableColumns = [
    { label: 'Awesems ID', key: 'chargingPointID', sortable: false },
    { label: 'Type auto', key: 'chargingPointCarType', sortable: false },
    { label: 'Wanneer is de laadpaal geplaatst', key: 'chargingPointPeriodType', sortable: false },
  ]

  const showOverlay = ref(false)

  const fetchOrders = (ctx, callback) => {
    showOverlay.value = true
    store.dispatch('app-order/fetchOrders', {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      status: statusFilter.value ? statusFilter.value.join(',') : null,
      userID: userFilter.value,
      q: searchQuery.value,
      dateFrom: dateFormFilter.value,
      dateTill: dateTillFilter.value,
      ecedoCreated: ecedoCreatedFilter.value,
      needsAttention: needsAttentionFilter.value,
    })
      .then(response => {
        const { items, _meta } = response.data
        showOverlay.value = false
        callback(items)
        totalOrders.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const electricityEanTableColumns = [
    { label: 'EAN', key: 'ean', sortable: false },
    { label: 'Status', key: 'status', sortable: false },
    { label: 'Gebruiksdoel', key: 'bagPurpose', sortable: false },
    { label: 'Meter type', key: 'meterType', sortable: false },
    { label: 'Verbruik (kWh)', key: 'marketSegment', sortable: false },
    { label: 'Teruglevering (kWh)', key: 'returnElectricitySingle', sortable: false },
  ]

  const GasTableColumns = [
    { label: 'EAN', key: 'ean', sortable: false },
    { label: 'Status', key: 'status', sortable: false },
    { label: 'Gebruiksdoel', key: 'bagPurpose', sortable: false },
    { label: 'Verbruik', key: 'marketSegment', sortable: false },
  ]

  const statusFilterOptions = [
    { value: 'NEW', label: 'Nieuw' },
    { value: 'SIGNED', label: 'Geaccepteerd' },
    { value: 'COOLINGDOWN_PERIOD_FINISHED', label: 'Einde bedenktijd' },
    { value: 'SENT', label: 'Bruto' },
    { value: ['SWITCH_SENT', 'SWITCHED'], label: 'Netto' },
    { value: ['LOSS', 'MOVE'], label: 'Vroegtijdig beëindigd' },
    { value: 'OTA', label: 'Onterechte switch' },
    { value: 'CANCELLED', label: 'Geannuleerd' },
    { value: 'MIXED', label: 'Mixed' },
    { value: 'EXPIRED', label: 'Verlopen' },
  ]

  const resolveStatusVariant = status => {
    if (status === 5) return 'info'
    if (status === 1 || status === 2) return 'info'
    if (status === 10) return 'success'
    if (status === 90 || status === 91 || status === 92) return 'danger'
    if (status === 93) return 'warning'
    return 'primary'
  }

  const resolveColorVariant = status => {
    if (status === 5) return '#a6a4b0'
    if (status === 1 || status === 2) return '#fdb813'
    if (status === 10) return '#28c76f'
    if (status === 90 || status === 91 || status === 92) return '#ea5455'
    if (status === 93) return '#ff9f43'
    return '#a6a4b0'
  }

  const getUsageType = type => {
    if (type === 'SMALLCONSUMER') return 'Kleinverbruik'
    if (type === 'LARGECONSUMER') return 'Grootverbruik'
    if (type === 'ARTICLE') return 'Artikel aansluiting'
    return ''
  }

  const getStatusType = status => {
    if (status === 'CREATED' || status === 'NEW') return { name: 'Nieuw', variant: 'info' }
    if (status === 'SIGNED') return { name: 'Geaccepteerd', variant: 'success' }
    if (status === 'COOLINGDOWN_PERIOD_FINISHED') return { name: 'Einde bedenktijd', variant: 'info' }
    if (status === 'SWITCH_SENT') return { name: 'Netto', variant: 'success' }
    if (status === 'SENT') return { name: 'Bruto', variant: 'success' }
    if (status === 'SWITCHED') return { name: 'Netto', variant: 'success' }
    if (status === 'LOSS') return { name: 'Vroegtijdig beëindigd', variant: 'danger' }
    if (status === 'MOVE') return { name: 'Vroegtijdig beëindigd', variant: 'danger' }
    if (status === 'OTA') return { name: 'Onterechte switch', variant: 'danger' }
    if (status === 'CANCELLED') return { name: 'Geannuleerd', variant: 'danger' }
    if (status === 'MIXED') return { name: 'Mixed', variant: 'info' }
    if (status === 'EXPIRED') return { name: 'Verlopen', variant: 'warning' }
    if (status === 'NEEDS_ATTENTION') return { name: 'Actie Vereist', variant: 'warning' }
    return { name: 'Nieuw', variant: 'info' }
  }

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveTypeVariant = status => {
    if (status === 1) return 'Particulier'
    if (status === 2) return 'Zakelijk'
    return ''
  }

  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refOfferListTable,
    sortByStatus,
    showOverlay,
    resolveInvoiceStatusVariantAndIcon,
    refetchData,
    resolveStatusVariant,
    resolveColorVariant,
    resolveTypeVariant,
    searchQuery,
    electricityEanTableColumns,
    GasTableColumns,
    tableEansColumns,
    getUsageType,
    getStatusType,
    chargingStationTableColumns,
    statusFilterOptions,
    userFilter,
    statusFilter,
    dateFormFilter,
    dateTillFilter,
    ecedoCreatedFilter,
    needsAttentionFilter,
  }
}
